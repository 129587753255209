import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/103_ip_marketing.md";

const IpMarketing = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpMarketing;
