import { Box } from "@mui/material";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo/logo.svg";
import logoLight from "../../assets/logo/logo_white.svg";
import { NAV_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const Logo = ({ lightTheme }) => {
  const [language, setLanguage] = useState(0);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Link
      to={`${NAV_ROUTES.get("HOME").PATHNAME}/${language}/`}
      style={{ textDecoration: "none" }}
    >
      <Box sx={styles.logoContainer}>
        <Box
          component="div"
          sx={styles.logo}
          style={
            lightTheme
              ? { backgroundImage: `url(${logoLight})` }
              : { backgroundImage: `url(${logo})` }
          }
        />
      </Box>
    </Link>
  );
};

export default Logo;
