import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/universal/001_user_creation.md";

const UserCreation = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default UserCreation;
