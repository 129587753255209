import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/201_patent_creation.md";

const PatentCreation = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default PatentCreation;
