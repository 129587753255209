import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/universal/103_ip_monetization.md";

const IpMonetization = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpMonetization;
