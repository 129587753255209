import React from "react";

import MarkdownContainer from "../MarkdownContainer";

import markdown from "../../../markdown/intro.md";

const Introduction = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default Introduction;
