import { theme } from "../../utils/theme";

export const styles = {
  background: {
    left: { xs: "-120%", md: "-100%" },
    position: "absolute",
    top: { xs: "-8%", md: "-24%" },
    zIndex: -1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  item: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-around",
    mb: { xs: 16, md: 18 },
    mt: { xs: 16, md: 18 },
  },
  itemImage: {
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    height: { xs: 240, md: 450 },
    width: { xs: "100%", md: 668 },
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  listBullet: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
    minHeight: 4,
    ml: { xs: 2, md: 2 },
    mr: { xs: 4, md: 4 },
    mt: { xs: 1.4, md: 2.4 },
    minWidth: 4,
  },
  listContainer: {
    pb: 3,
    pl: { xs: 1, md: 1 },
    pr: { xs: 1, md: 0 },
    pt: { xs: 8, md: 8 },
    width: { xs: "100%", md: "100%" },
  },
  listHeader: {
    fontSize: {
      xs: theme.typography.fontSize * 1.5,
      md: theme.typography.fontSize * 1.5,
    },
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.1,
    mb: { xs: 5, md: 5 },
    textTransform: "capitalize",
  },
  listItem: {
    alignItems: "flex-start",
    display: "flex",
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.15,
    },
    lineHeight: 1.5,
    mb: { xs: 3, md: 3 },
  },
  productItems: {
    width: "100%",
  },
  productRoot: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  tab: {
    color: theme.palette.grays[500],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 300,
    pl: { xs: 0, md: 2 },
    pr: { xs: 0, md: 2 },
    textTransform: "capitalize",
    "&.Mui-selected": {
      color: theme.palette.grays.black,
      fontWeight: 700,
    },
  },
  tabs: {
    borderBottomColor: theme.palette.grays[300],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: { xs: "flex", md: "flex" },
    justifyContent: "space-between",
    mb: { xs: 8, md: 8 },
    width: { xs: "100%", md: "108%" },
  },
  video: {
    clipPath: "inset(1px 1px)",
    height: "100%",
    width: "100%",
  },
  videoContainer: {
    height: { xs: 252, sm: 252, md: 374, lg: 374 },
    overflow: "hidden",
    position: "relative",
    width: { xs: 326, sm: 326, md: 358, lg: 474 },
    // height: { xs: 252, sm: 480, md: 374 },
    // width: { xs: 327, sm: 600, md: 474 },
    zIndex: 999,
  },
  videoPosition: {
    height: "110%",
    position: { xs: "relative", md: "absolute" },
    right: { xs: 55, sm: 55, md: -62, lg: -80 },
    top: { xs: -21, sm: -21, md: -30, lg: -30 },
    // right: { xs: 56, sm: 102, md: -80 },
    // top: { xs: -21, sm: -45, md: -30 },
    width: "134%",
  },
};
