import { theme } from "../../utils/theme";

export const styles = {
  decoration: {
    background: theme.palette.secondary.main,
    height: "1px",
    ml: 3,
    width: { xs: 48, md: 72 },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: "center",
    color: theme.palette.grays[600],
    display: "flex",
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1,
    },
    fontWeight: 400,
    lineHeight: { xs: 1.3, md: 1.4 },
    mb: 2,
    mt: 0,
    textTransform: "uppercase",
  },
  headerContent: {
    color: theme.palette.grays.black,
    fontSize: {
      xs: theme.typography.fontSize * 1.35,
      md: theme.typography.fontSize * 1.25,
    },
    fontWeight: 300,
    // lineHeight: { xs: 1.3, md: 1.4 },
    mb: 1,
    mt: { xs: 8, md: 8 },
    textTransform: "capitalize",
  },
  headerRoot: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", md: "100%" },
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  subheader: {
    alignItems: "center",
    color: theme.palette.grays.black,
    fontSize: {
      xs: theme.typography.fontSize * 1.75,
      md: theme.typography.fontSize * 2,
    },
    fontWeight: 900,
    letterSpacing: 1.2,
    lineHeight: 1,
    textTransform: "capitalize",
  },
};
