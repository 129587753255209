import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SwipeableViews from "react-swipeable-views";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Value = () => {
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();

  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSelect = (input) => () => {
    setValue(input);
    setIsSelectMenuOpen(false);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={styles.valueRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("VALUE").ID}>
        <Header data={content.value} />

        {/* Tabs (Desktop) */}
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={styles.tabs}
        >
          {content.value.items.map((item, index) => {
            return (
              <Tab
                key={index}
                label={t(item.label)}
                disableRipple
                sx={styles.tab}
              />
            );
          })}
        </Tabs>

        {/* Dropdown Select (Mobile) */}
        <Box sx={styles.selectFormContainer}>
          <Box
            sx={styles.selectButton}
            onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}
          >
            <Typography sx={styles.selectFormValue}>
              {t(
                content.value.items.find((f) => {
                  if (f.value === value) return f;
                }).label
              )}
            </Typography>

            <Box sx={styles.grow} />

            {isSelectMenuOpen ? (
              <RiArrowUpSLine size="1.2em" />
            ) : (
              <RiArrowDownSLine size="1.2em" />
            )}
          </Box>

          {isSelectMenuOpen && (
            <Box sx={styles.selectMenu}>
              {content.value.items.map((option, index) => {
                return (
                  <Box
                    sx={styles.selectOption}
                    key={index}
                    onClick={handleChangeSelect(option.value)}
                  >
                    <Typography sx={styles.selectOptionLabel}>
                      {t(option.label)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>

        {/* Carousel */}
        <Box ref={setRef} sx={styles.body}>
          <SwipeableViews
            enableMouseEvents
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {content.value.items.map((item, index) => {
              return (
                <Grid
                  container
                  direction="row"
                  key={index}
                  spacing={8}
                  sx={styles.content}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={styles.contentImageContainer}
                    style={{
                      backgroundImage: `url(${item?.image})`,
                    }}
                  ></Grid>

                  <Grid item xs={12} md={6} sx={styles.listContainer}>
                    <Typography sx={styles.listHeader}>
                      {t(item.label)}
                    </Typography>

                    {item.unorderedItems.map((ele, idx) => (
                      <Box key={idx} sx={styles.listItem}>
                        <Box sx={styles.listBullet} />
                        <Typography>{t(ele)}</Typography>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </SwipeableViews>
        </Box>
      </SectionLayout>
    </Box>
  );
};

export default Value;
