import { useEffect, useContext, useState } from "react";

import { GlobalContext } from "../contexts/GlobalContext";

export const useInView = (options) => {
  const { state, dispatch } = useContext(GlobalContext);
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setVisible(true);
      }
    }, options);

    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [ref, options]);

  return [setRef, visible];
};
