import React from "react";

import MarkdownContainer from "../MarkdownContainer";

import markdown from "../../../markdown/gettingStarted/quick_start.md";

const GettingStarted = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default GettingStarted;
