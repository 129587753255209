import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/universal/104_ip_commercialization.md";

const IpCommercialization = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpCommercialization;
