import { theme } from "../../utils/theme";

export const styles = {
  background: {
    left: { xs: "-120%", md: "-100%" },
    position: "absolute",
    top: { xs: "-8%", md: "-24%" },
    zIndex: -1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  image: {
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: { xs: 226, md: 837 },
    width: { xs: 380, md: 1404 },
  },
  item: {
    borderRadius: 5,
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    gap: 6,
    minHeight: { xs: 280, sm: 280, md: 340 },
    pb: 11,
    pl: 8,
    pr: 8,
    pt: 11,
  },
  itemBody: {
    color: theme.palette.grays[600],
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 0.88,
    },
    textAlign: { xs: "left", md: "left" },
  },
  itemContent: {
    alignItems: { xs: "start", md: "start" },
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    gap: 3,
    justifyContent: { xs: "start", md: "start" },
  },
  itemHeader: {
    fontSize: {
      xs: theme.typography.fontSize * 1.35,
      md: theme.typography.fontSize * 1.35,
    },
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.1,
  },
  itemImage: {
    backgroundPosition: "left center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: { xs: 70, md: 70 },
    width: { xs: 70, md: 70 },
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  video: {
    backgroundColor: "transparent !important",
    clipPath: "inset(1px 1px)",
    height: "98%",
    width: "98%",
  },
  videoContainer: {
    display: "flex",
    height: { xs: 204, sm: 392, md: 540, lg: 632 },
    justifyContent: "center",
    overflow: "hidden",
    mt: 4,
    position: "relative",
    width: "100%",
  },
  videoPosition: {
    height: "100%",
    position: { xs: "relative", md: "absolute" },
    width: "100%",
  },
};
