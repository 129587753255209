import { glass, theme } from "../../utils/theme";

export const styles = {
  bodyText: {
    color: theme.palette.grays.black,
    fontSize: {
      xs: theme.typography.fontSize * 1.35,
      md: theme.typography.fontSize * 1.25,
    },
    fontWeight: 300,
    mb: 1,
    mt: { xs: 8, md: 8 },
    textTransform: "none",
    width: "100%",
  },
  button: {
    backgroundColor: theme.palette.grays.black,
    borderRadius: 50,
    fontSize: {
      xs: theme.typography.fontSize * 0.75,
      md: theme.typography.fontSize * 0.88,
    },
    mt: theme.spacing(5),
    pb: { xs: 2, md: 3 },
    pt: { xs: 2, md: 3 },
    textTransform: "none",
    width: { xs: 200, md: 200 },
  },
  contactBackground: {
    height: "50%",
    right: { xs: "-90%", md: "-100%" },
    position: "absolute",
    top: { xs: "20%", md: "-60%" },
    zIndex: -1,
  },
  contactRoot: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  error: {
    color: theme.palette.danger.main,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(7),
    width: "100%",
  },
  formRow: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 1,
    marginTop: 1,
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      minWidth: "80%",
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  input: {
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: 'none',
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  inputBase: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
    padding: 2,
    width: "100%",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectButton: {
    alignItems: "center",
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    color: theme.palette.grays[500],
    cursor: "pointer",
    display: "flex",
    mb: 0.5,
    padding: 2,
  },
  selectFormContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    mr: 2,
    position: "relative",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  selectFormLabel: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
  },
  selectFormValue: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
  },
  selectMenu: {
    alignItems: "flex-start",
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(3)} 0`,
    zIndex: 1,
  },
  selectMenuLabel: {
    color: theme.palette.grays[500],
    fontSize: theme.typography.fontSize * 0.88,
    mb: 1.2,
    padding: `0 ${theme.spacing(5)}`,
  },
  selectOption: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    width: "100%",
    "&:hover": {
      background: "rgba(210, 210, 210, 0.2)",
    },
  },
  selectOptionLabel: {
    color: theme.palette.grays.black,
  },
  selectOptionLabelActive: {
    color: theme.palette.primary.light,
  },
};
