import { Box, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import Footer from "../../components/Footer";
import SideMenu from "../../components/docPageComponents/SideMenu";
import SideMenuMobile from "../../components/docPageComponents/SideMenuMobile";

import { DOCS_OPTIONS } from "../../utils/optionUtils";
import { DOC_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const DocsLayout = () => {
  const location = useLocation();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let navigate = useNavigate();

  useEffect(() => {
    const paths = location.pathname.split("/");

    if (paths.length < 4) {
      navigate(DOC_ROUTES.get("INTRODUCTION").PATH);
    }
  }, [location]);

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.body}>
        {matches ? (
          <SideMenuMobile options={DOCS_OPTIONS} />
        ) : (
          <SideMenu options={DOCS_OPTIONS} />
        )}

        <Box sx={styles.scrollableContainer}>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </Box>
      </Box>

      <Footer />
    </Stack>
  );
};

export default DocsLayout;
