import {
  Box,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { GlobalContext } from "../../contexts/GlobalContext";
import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Product = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [setRef, visible] = useInView({});
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (visible) {
      dispatch({
        type: "SET_CURRENT_VIEW",
        payload: NAV_SUBROUTES.get("PRODUCT").VALUE,
      });
    }
  }, [visible]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.productRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("PRODUCT").ID}>
        <Header data={content.product} />

        <Stack ref={setRef} spacing={10}>
          {content.product?.items.slice(0, 3).map((item, index) => {
            return (
              <Box key={index}>
                <Grid
                  container
                  direction={index % 2 === 0 ? "row-reverse" : "row"}
                  mb={24}
                  spacing={0}
                  sx={styles.item}
                >
                  <Grid item xs={12} sm={5}>
                    <Box sx={styles.videoContainer}>
                      <Box sx={styles.videoPosition}>
                        <video autoPlay loop muted style={styles.video}>
                          <source src={item.video} type="video/mp4" />
                        </video>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={styles.listContainer}>
                    <Typography sx={styles.listHeader}>
                      {t(item.header)}
                    </Typography>

                    {item.list_items.map((ele, idx) => (
                      <Box key={idx} sx={styles.listItem}>
                        <Box sx={styles.listBullet} />
                        <Typography>{t(ele)}</Typography>
                      </Box>
                    ))}
                  </Grid>
                </Grid>

                {/* {content.product?.items.length !== index + 1 && (
                  <Divider style={{ borderStyle: "dashed" }} />
                )} */}
              </Box>
            );
          })}

          {content.product?.items.slice(-1).map((item, index) => {
            return (
              <Grid
                container
                direction="row"
                key={item.header}
                mb={24}
                spacing={0}
                sx={styles.item}
              >
                <Grid item xs={12} sm={5}>
                  <Tabs
                    centered
                    onChange={handleChangeTab}
                    sx={styles.tabs}
                    variant="fullWidth"
                    value={tabValue}
                  >
                    {item.tabs.map((tab, index) => {
                      return (
                        <Tab
                          key={index}
                          label={t(tab)}
                          disableRipple
                          sx={styles.tab}
                          wrapped
                        />
                      );
                    })}
                  </Tabs>

                  {item.videos.map((ele, idx) => {
                    // if (tabValue !== idx) return;

                    return (
                      <Box
                        key={idx}
                        sx={{
                          ...styles.videoContainer,
                          display: tabValue === idx ? "block" : "none",
                        }}
                      >
                        <Box sx={styles.videoPosition}>
                          <video
                            id="_product_video"
                            autoPlay
                            loop
                            muted
                            style={styles.video}
                          >
                            <source src={ele} type="video/mp4" />
                          </video>
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>

                <Grid item xs={12} sm={4} sx={styles.listContainer}>
                  <Typography sx={styles.listHeader}>
                    {t(item.header)}
                  </Typography>

                  {item.list_items.map((ele, idx) => (
                    <Box key={idx} sx={styles.listItem}>
                      <Box sx={styles.listBullet} />
                      <Typography>{t(ele)}</Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </SectionLayout>
    </Box>
  );
};

export default Product;
