import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
export const theme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 16,
    fontFamily: [
      "Montserrat",
      // "Kulim Park",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    useNextVariants: true,
  },
  palette: {
    background: {
      default: "#fff",
      dark: "#030A0E",
      glass: "rgba(255, 255, 255, 0.2)",
    },
    primary: {
      main: "#AC539F",
      light: "#BB6BD9",
      gradient: "linear-gradient(90deg, #7C1CFD -5.73%, #139AF6 88.78%)",
    },
    secondary: {
      main: "#0F62FE",
    },
    danger: {
      main: red.A400,
    },
    grays: {
      white: "#fff",
      100: "#f6f8fa",
      200: "#f0f2f5",
      300: "#e4e6ef",
      400: "#b6b6c4",
      500: "#a2a6b8",
      600: "#7e8299",
      700: "#5e6278",
      800: "#4f5058",
      900: "#404254",
      1000: "#121526",
      1100: "#07080f",
      black: "#0D0D0D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // background: "linear-gradient(90deg, #7C1CFD -5.73%, #139AF6 88.78%)",
          borderRadius: 0,
          // boxShadow:
          //   "0px 3px 4px rgba(56, 108, 243, 0.1), 0px 8px 8px rgba(56, 108, 243, 0.1), 0px 20px 20px rgba(56, 108, 243, 0.1)",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 700,
          letterSpacing: 2,
          paddingBottom: 16,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 16,
          textTransform: "none",
          transition: "all 0.5s ease-in-out",
          // "&:hover": {
          //   boxShadow:
          //     "0px 3px 6px rgba(56, 108, 243, 0.28), 0px 8px 10px rgba(56, 108, 243, 0.28), 0px 20px 20px rgba(56, 108, 243, 0.28)",
          // },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // background: "linear-gradient(90deg, #7C1CFD -5.73%, #139AF6 88.78%)",
        },
      },
    },
  },
});

export const appBar = {
  height: 50,
  width: "100%",
};

export const bodyWidth = {
  maxWidth: 1296,
  width: "95%",
};

export const glass = {
  background: "rgba(255, 255, 255, 0.2)",
  backdropFilter: "blur(5px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  boxShadow: "0px 4px 12px rgba(184, 184, 184, 0.25)",
};
