import { Box, Fade, Grid, Grow, Typography } from "@mui/material";
import i18n from "i18next";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

// const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { GlobalContext } from "../../contexts/GlobalContext";
import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Overview = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [setRef, visible] = useInView({});
  const { t } = useTranslation();

  // useEffect(() => {
  // URL =
  //   i18n.language === "zh-cn"
  //     ? "https://www.youtube.com/watch?v=GvatYHqdX6c"
  //     : "https://www.youtube.com/watch?v=EFhLkISWpnU";
  // }, [i18n.language]);

  useEffect(() => {
    if (visible) {
      dispatch({
        type: "SET_CURRENT_VIEW",
        payload: NAV_SUBROUTES.get("PRODUCT").VALUE,
      });
    }
  }, [visible]);

  return (
    <Box sx={styles.root}>
      <SectionLayout id={NAV_SUBROUTES.get("OVERVIEW").ID}>
        <Header data={content.overview} />

        <Grid
          container
          direction="row"
          mt={{ xs: 4, md: 4 }}
          ref={setRef}
          spacing={5}
        >
          {content.overview?.items.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <Grow in={visible} timeout={1000 + index * 500}>
                <Box sx={{ ...styles.item, background: item.background }}>
                  {/* Image */}

                  <Box
                    sx={{
                      ...styles.itemImage,
                      backgroundImage: `url(${item?.image})`,
                    }}
                  />

                  {/* Content */}
                  <Fade in={visible} timeout={600 + index}>
                    <Box sx={styles.itemContent}>
                      <Typography sx={styles.itemHeader}>
                        {t(item.header)}
                      </Typography>

                      <Typography sx={styles.itemBody}>
                        {t(item.content)}
                      </Typography>
                    </Box>
                  </Fade>
                </Box>
              </Grow>
            </Grid>
          ))}

          <Grid item xs={12} md={12} sx={styles.item}>
            <Box sx={styles.videoContainer}>
              <Box sx={styles.videoPosition}>
                {i18n.language && i18n.language === "zh-cn" ? (
                  <video
                    autoPlay
                    id="_overview_video"
                    key={content.overview.videos.zh}
                    loop
                    muted
                    style={styles.video}
                  >
                    <source src={content.overview.videos.zh} type="video/mp4" />
                  </video>
                ) : (
                  <video
                    autoPlay
                    id="_overview_video"
                    key={content.overview.videos.en}
                    loop
                    muted
                    style={styles.video}
                  >
                    <source src={content.overview.videos.en} type="video/mp4" />
                  </video>
                )}
                {/* <ReactPlayer
                  url={URL}
                  loop={true}
                  muted={true}
                  playing={true}
                  playbackRate={2}
                  height={"98%"}
                  width={"98%"}
                  style={{ pointerEvents: "none" }}
                  onError={handleVideoError}
                /> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </SectionLayout>
    </Box>
  );
};

export default Overview;
