import { Box, Fade, Grid, Slide, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const About = () => {
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();

  return (
    <Box sx={styles.aboutRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("ABOUT").ID}>
        <Grid
          container
          direction="row"
          ref={setRef}
          spacing={0}
          sx={styles.body}
        >
          <Fade in={visible} timeout={600}>
            <Grid item xs={12} md={5}>
              <Header data={content.about} />

              <Typography sx={styles.bodyText}>
                {t(content.about.content)}
              </Typography>
            </Grid>
          </Fade>

          <Fade in={visible} timeout={600}>
            <Grid
              item
              xs={12}
              md={6}
              sx={styles.image}
              style={{
                background: `url(${content.about.image})`,
              }}
            ></Grid>
          </Fade>
        </Grid>
      </SectionLayout>
    </Box>
  );
};

export default About;
