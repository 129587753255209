import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/101_ip_creation.md";

const IpCreation = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpCreation;
