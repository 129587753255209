import { appBar, bodyWidth, theme } from "../../utils/theme";

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  sectionBody: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    pl: { xs: 4, sm: 8 },
    pr: { xs: 4, sm: 8 },
    width: "100%",
    zIndex: 1,
  },
  sectionContainer: {
    alignItems: "start",
    display: "flex",
    justifyContent: "center",
    pb: { xs: 6, md: 20 },
    pt: { xs: 12, md: 20 },
    position: "relative",
    width: { xs: "100%", md: "100%", lg: bodyWidth.maxWidth },
  },
};
