import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./style";

const Header = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.headerRoot}>
      <Box sx={styles.header}>
        {t(data.header)}
        {/* <Box sx={styles.decoration} /> */}
      </Box>

      <Typography sx={styles.subheader}>{t(data.subheader)}</Typography>

      {data.body && (
        <Typography sx={styles.headerContent}>
          {t(data.body)}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
