import { Box, Dialog, Slide, Typography } from "@mui/material";
import i18n from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowRightLine, RiCloseLine, RiMenuLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../Logo";
import NavLanguageSelect from "../NavLanguageSelect";

import { NAV_OPTIONS } from "../../../utils/optionUtils";
import { NAV_ROUTES } from "../../../utils/routes";

import { styles } from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NavMenu = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnClickLogo = () => {
    navigate(`${NAV_ROUTES.get("HOME").PATHNAME}/${i18n.language}/`);

    setIsMenuOpen(false);
  };

  return (
    <Box sx={styles.navMenuContainer}>
      <Box sx={styles.headerIcon}>
        <RiMenuLine onClick={() => setIsMenuOpen(true)} size="1.2em" />
      </Box>

      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          style: { ...styles.dialog },
        }}
        TransitionComponent={Transition}
      >
        <Box sx={styles.dialogHeaderContainer}>
          <Box sx={styles.dialogHeader}>
            <Box onClick={handleOnClickLogo}>
              <Logo />
            </Box>

            <Box sx={styles.grow} />

            <RiCloseLine onClick={() => setIsMenuOpen(false)} size="1.2em" />
          </Box>
        </Box>

        <Box sx={styles.dialogListContainer}>
          {Array.from(NAV_OPTIONS).map(([key, value]) => {
            return (
              <Link
                key={value.LABEL}
                to={`${i18n.language}/${value.PATH}`}
                style={{ textDecoration: "none" }}
                onClick={() => setIsMenuOpen(false)}
              >
                <Typography sx={styles.navText}>
                  {t(value.LABEL)}

                  <Box sx={styles.icon}>
                    <RiArrowRightLine />
                  </Box>
                </Typography>
              </Link>
            );
          })}

          <Box sx={styles.grow} />

          <NavLanguageSelect
            horizontal={{ left: 54 }}
            showValue
            vertical={{ bottom: 50 }}
          />
        </Box>

        {/* <Box sx={styles.dialogBackground}>
          <video autoPlay loop muted style={styles.dialogVideo}>
            <source src={"videos/nav_menu_background.mp4"} type="video/mp4" />
          </video>
        </Box> */}
      </Dialog>
    </Box>
  );
};

export default NavMenu;
