import { Box, ClickAwayListener, Paper, Typography } from "@mui/material";
import i18n from "i18next";
import React, { useContext, useState } from "react";
import { RiArrowDownSFill, RiArrowUpSFill, RiGlobalLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { saveLanguage } from "../../../i18n-locales/i18n-translation";
import { LANGUAGES_OPTIONS } from "../../../utils/optionUtils";

import { styles } from "./styles";

const NavLanguageSelect = ({ horizontal, showValue, vertical }) => {
  const { state, dispatch } = useContext(GlobalContext);

  const location = useLocation();
  let navigate = useNavigate();
  const { lang } = useParams();

  const [languageAnchorEl, setLanguageAnchorEl] = useState(false);

  const handleClickSelect = () => {
    setLanguageAnchorEl(!languageAnchorEl);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(false);
  };

  const handleClickLanguage = (lng) => (evt) => {
    saveLanguage(lng);

    /** Updata URL */
    navigate(location.pathname.replace(lang, lng));

    handleCloseLanguageMenu();
  };

  return (
    <ClickAwayListener onClickAway={handleCloseLanguageMenu}>
      <Box sx={styles.selectContainer}>
        <Box sx={styles.selectFormContainer} onClick={handleClickSelect}>
          <Box sx={styles.selectFormIcon}>
            <RiGlobalLine size={"1.4em"} />
          </Box>

          {showValue && (
            <>
              <Box sx={styles.selectFormLabelContainer}>
                <Typography sx={styles.selectFormLabel}>
                  {
                    Array.from(LANGUAGES_OPTIONS.values()).find(
                      (value) => value.KEY === i18n.language
                    ).LABEL
                  }
                </Typography>
              </Box>

              <Box sx={styles.grow} />

              {languageAnchorEl ? (
                <RiArrowUpSFill size="1.2em" style={styles.selectFormArrow} />
              ) : (
                <RiArrowDownSFill size="1.2em" style={styles.selectFormArrow} />
              )}
            </>
          )}
        </Box>

        {languageAnchorEl && (
          <Paper
            elevation={3}
            style={{ ...horizontal, ...vertical }}
            sx={
              Object.keys(vertical).includes("top")
                ? styles.selectMenuAnchorTop
                : styles.selectMenuAnchorBottom
            }
          >
            {Array.from(LANGUAGES_OPTIONS.keys()).map((option, index) => (
              <Box
                key={index}
                onClick={handleClickLanguage(LANGUAGES_OPTIONS.get(option).KEY)}
                sx={styles.selectOption}
                value={i18n.language}
                defaultValue="en"
              >
                <Typography
                  sx={styles.selectOptionLabel}
                  style={
                    i18n.language === LANGUAGES_OPTIONS.get(option).KEY
                      ? styles.selectOptionLabelActive
                      : null
                  }
                >
                  {LANGUAGES_OPTIONS.get(option).LABEL}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NavLanguageSelect;
