import {
  Box,
  Button,
  Grow,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import i18n from "i18next";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import hero_image_1 from "../../assets/images/hero/Hero_1.png";
import hero_image_1_chi from "../../assets/images/hero/Hero_1_chi.png";
import hero_image_2 from "../../assets/images/hero/Hero_2.png";
import hero_image_2_chi from "../../assets/images/hero/Hero_2_chi.png";
import hero_image_3 from "../../assets/images/hero/Hero_3.png";
import hero_image_3_chi from "../../assets/images/hero/Hero_3_chi.png";
import hero_image_4 from "../../assets/images/hero/hero_4.png";
import hero_image_4_chi from "../../assets/images/hero/hero_4_chi.png";
import hero_combine from "../../assets/images/hero/hero_combine.png";
import hero_combine_chi from "../../assets/images/hero/hero_combine_chi.png";
import hero_background from "../../assets/images/hero/hero_bg.png";

import { GlobalContext } from "../../contexts/GlobalContext";
import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_ROUTES, NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const Hero = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      dispatch({
        type: "SET_CURRENT_VIEW",
        payload: NAV_ROUTES.get("HOME").VALUE,
      });
    } else {
      dispatch({
        type: "SET_CURRENT_VIEW",
        payload: undefined,
      });
    }
  }, [visible]);

  const handleOnClickNavigateApp = () => {
    window.open("https://webapp.ip8value.cn/", "_blank");
  };

  const handleOnClickRequest = () => {
    document
      .querySelector(`#${NAV_SUBROUTES.get("CONTACT").ID}`)
      ?.scrollIntoView({
        behavior: "smooth",
      });
  };

  return (
    <Box id={NAV_ROUTES.get("HOME").ID} ref={setRef} sx={styles.heroContainer}>
      <Box sx={styles.heroForeground}>
        {matches ? (
          <Box sx={styles.heroImageContainer}>
            <Box
              sx={styles.heroImageBackgroundMobile}
              style={{
                backgroundImage: `url(${
                  i18n.language === "en" ? hero_combine : hero_combine_chi
                })`,
              }}
            />
          </Box>
        ) : (
          <Box sx={styles.heroImageContainer}>
            <Box
              sx={{
                ...styles.heroImage1,
                backgroundImage: `url(${
                  i18n.language === "en" ? hero_image_1 : hero_image_1_chi
                })`,
              }}
            />

            <Grow in={visible} timeout={1000 + 500}>
              <Box
                sx={{
                  ...styles.heroImage2,
                  backgroundImage: `url(${
                    i18n.language === "en" ? hero_image_2 : hero_image_2_chi
                  })`,
                }}
              />
            </Grow>

            <Grow in={visible} timeout={1000 + 500}>
              <Box
                sx={{
                  ...styles.heroImage3,
                  backgroundImage: `url(${
                    i18n.language === "en" ? hero_image_3 : hero_image_3_chi
                  })`,
                }}
              />
            </Grow>

            <Grow in={visible} timeout={1000 + 500}>
              <Box
                sx={{
                  ...styles.heroImage4,
                  backgroundImage: `url(${
                    i18n.language === "en" ? hero_image_4 : hero_image_4_chi
                  })`,
                }}
              />
            </Grow>

            <Grow in={visible} timeout={1000 + 2 * 500}>
              <Box sx={styles.heroImageBubble1}>{t("A.I.")}</Box>
            </Grow>

            <Grow in={visible} timeout={1000 + 3 * 500}>
              <Box sx={styles.heroImageBubble2}>{t("LLM")}</Box>
            </Grow>

            <Grow in={visible} timeout={1000 + 4 * 500}>
              <Box sx={styles.heroImageBubble3}>{t("Blockchain")}</Box>
            </Grow>

            <Box
              sx={styles.heroImageBackground}
              style={{
                backgroundImage: `url(${hero_background})`,
              }}
            />
          </Box>
        )}

        <Box sx={styles.heroBody}>
          <Stack spacing={4}>
            <Box sx={styles.heroHeaderContainer}>
              <Typography sx={styles.heroHeaderLarge}>
                {t(content.hero.header[0])}
              </Typography>

              <Typography sx={styles.heroHeaderSmall}>
                {t(content.hero.header[1])}
              </Typography>
            </Box>

            <Box sx={styles.heroHeaderContainer}>
              <Typography sx={styles.heroHeaderSmall}>
                {t(content.hero.header[2])}
              </Typography>

              <Typography
                sx={{
                  ...styles.heroHeaderLarge,
                  // color: theme.palette.primary.main,
                }}
              >
                {t(content.hero.header[3])}
              </Typography>
            </Box>

            <Typography sx={styles.heroText}>
              {t(content.hero.content)}
            </Typography>
          </Stack>

          <Box sx={styles.heroAction}>
            <Button
              color={"secondary"}
              sx={styles.buttonPrimary}
              onClick={handleOnClickNavigateApp}
              variant="contained"
            >
              {t("Try it free")}
            </Button>

            <Button
              color={"secondary"}
              sx={styles.buttonSecondary}
              onClick={handleOnClickRequest}
              variant="outlined"
            >
              {t("Request a Demo")}
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.heroBackground} />

      {/* <Wave
        style={styles.heroWave}
        fill="#fff"
        paused={false}
        options={{
          height: 20,
          amplitude: 36,
          speed: 0.15,
          points: 3,
        }}
      />
      <Wave
        style={styles.heroWave}
        fill="rgba(255,255,255,0.5)"
        paused={false}
        options={{
          height: 20,
          amplitude: 36,
          speed: 0.2,
          points: 3,
        }}
      />
      <Wave
        style={styles.heroWave}
        fill="rgba(255,255,255,0.3)"
        paused={false}
        options={{
          height: 20,
          amplitude: 36,
          speed: 0.25,
          points: 3,
        }}
      /> */}
    </Box>
  );
};

export default Hero;
