import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/universal/002_user_deletion.md";

const UserDeletion = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default UserDeletion;
