import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import NavLanguageSelect from "../NavLanguageSelect";

import { NAV_OPTIONS } from "../../../utils/optionUtils";

import { styles } from "./style";

const NavList = () => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.navListContainer}>
      {Array.from(NAV_OPTIONS).map(([key, value]) => {
        return (
          <Link
            key={value.LABEL}
            to={`${i18n.language}/${value.PATH}`}
            style={{ textDecoration: "none" }}
          >
            <Typography sx={styles.navTextInactive}>
              {t(value.LABEL)}
            </Typography>
          </Link>
        );
      })}

      <NavLanguageSelect horizontal={{ right: 16 }} vertical={{ top: 40 }} />
    </Box>
  );
};

export default NavList;
