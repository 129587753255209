import { theme } from "../../utils/theme";

export const styles = {
  aboutRoot: {
    alignItems: "center",
    background:
    "linear-gradient(300deg, rgba(4, 104, 214, 0.18) 1.09%, rgba(225, 150, 198, 0.18) 32.33%, rgba(255, 190, 170, 0.18) 59.9%)",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  background: {
    filter: "brightness(0.7) blur(0) grayscale(1)",
    height: "100%",
    right: { xs: 0, md: 0 },
    top: { xs: 0, md: 0 },
    position: "absolute",
    width: { xs: 600, md: "100%" },
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  bodyText: {
    color: theme.palette.grays.black,
    fontSize: {
      xs: theme.typography.fontSize * 1.35,
      md: theme.typography.fontSize * 1.25,
    },
    fontWeight: 300,
    mb: 1,
    mt: { xs: 8, md: 8 },
    textTransform: "none",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  image: {
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: { xs: 286, sm: 540, md: 500 },
    mt: { xs: 8, md: 0 },
    width: { xs: "100%", md: 666 },
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
