import { Button, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { RiCloseLine } from "react-icons/ri";

import { GlobalContext } from "../../contexts/GlobalContext";

import { styles } from "./style";

const CustomSnackbar = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const { snackbar } = state;

  return (
    <Snackbar
      // action={
      // <Button
      //   color="inherit"
      //   sx={styles.button}
      //   onClick={() => setSnackbar({ ...snackbar, isOpen: false })}
      // >
      //   <RiCloseLine size="1.4em" />
      // </Button>
      // }
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={3000}
      open={snackbar.isOpen}
      onClose={() =>
        dispatch({
          type: "SHOW_SNACKBAR",
          payload: {
            message: "",
            isOpen: false,
          },
        })
      }
      message={snackbar.message}
      ContentProps={{ sx: styles.snackbar }}
    />
  );
};

export default CustomSnackbar;
