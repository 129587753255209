export const allFirstCharToUpperCase = (text) => {
  const regex = /\b\w/g;
  return text.replace(regex, (char) => char.toUpperCase());
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const detectCurrentPath = (currentPath, pathname) => {
  const pathLastSegment = currentPath.split("/").slice(-1)[0];

  return currentPath === pathname || pathLastSegment === pathname;
};
