import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/105_ip_sync.md";

const IpSynchronization = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpSynchronization;
