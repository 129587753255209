import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";

import { GlobalContext } from "../../contexts/GlobalContext";
import { theme } from "../../utils/theme";

import { styles } from "./styles";

const Loader = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const { loading } = state;

  return loading.isOpen && (
    <Box sx={styles.loaderContainer}>
      <InfinitySpin color={theme.palette.primary.main} width="188" />
      <Typography sx={styles.text}>{t("One moment please...")}</Typography>
    </Box>
  );
};

export default Loader;
