import { theme } from "../../../utils/theme";

const navText = {
  alignItems: "center",
  borderRadius: 50,
  color: theme.palette.grays.black,
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  fontSize: theme.typography.fontSize * 0.88,
  fontWeight: 600,
  justifyContent: "center",
  letterSpacing: 0.5,
  minWidth: "fit-content",
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 1,
  position: "relative",
  textDecoration: "none",
  transition: "all 0.5s ease-in-out",
};

export const styles = {
  indicator: {
    bottom: 0,
    height: 3,
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  navListContainer: {
    alignItems: "center",
    display: { md: "flex", xs: "none" },
    flex: 1,
    flexDirection: "row",
    gap: 1,
    justifyContent: "flex-end",
  },
  navTextActive: {
    ...navText,
    backgroundColor: theme.palette.primary.main,
  },
  navTextInactive: {
    ...navText,
    backgroundColor: "transparent",
    "&:hover": {
      color: theme.palette.grays[500],
    },
  },
};
