import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import About from "../../components/About";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Hero from "../../components/Hero";
import Overview from "../../components/Overview";
import Product from "../../components/Product";
import Value from "../../components/Value";

import { GlobalContext } from "../../contexts/GlobalContext";
import { appBar } from "../../utils/theme";

import { styles } from "./styles";

const HomePage = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      handleScrollToSection(location.hash);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleScrollToSection = (hash) => {
    document.querySelector(hash)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        cursor: "default",
        marginTop: `-${appBar.height + 34}px`,
        overflowX: { xs: "hidden", sm: "hidden", md: "clip" },
        width: "100%",
      }}
    >
      <Hero />

      <Overview />

      <Value />

      <Product />

      <About />

      <Contact />

      <Footer />
    </Box>
  );
};

export default HomePage;
