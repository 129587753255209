import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/102_ip_migration.md";

const IpMigration = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpMigration;
