import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./en/translation.json";
import translationZhCn from "./zh-cn/translation.json";

export const LANGUAGE_KEY = "ip8-lng";

export const saveLanguage = (lng) => {
  /** Updata i18n */
  i18n.changeLanguage(lng);
  /** Updata localStorage */
  localStorage.setItem(LANGUAGE_KEY, lng);
};

i18n.use(LanguageDetector).init({
  debug: false,
  lng: localStorage.getItem(LANGUAGE_KEY) || "en",
  fallbackLng: "en",

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translations: translationEn,
    },
    zh: {
      translations: translationZhCn,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
