import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";

import DocsLayout from "./pages/DocsPage";
import HomePage from "./pages/HomePage";
import OverallLayout from "./layouts/OverallLayout";

import {
  DOC_ROUTES,
  ENTERPRISE_ROUTES,
  NAV_ROUTES,
  NAV_SUBROUTES,
  UNIVERSAL_ROUTES,
} from "./utils/routes";

import "./App.css";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          IP8Value: Intellectual Asset Management for Technology Innovation &
          Commercialization
        </title>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap"
          rel="stylesheet"
        />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta property="og:url" content="https://www.ip8value.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="IP8Value: Intellectual Asset Management for Technology Innovation & Commercialization"
        />
        <meta
          property="og:description"
          content="Our platform creates a deep tech community that accelerates the communication in technology innovation, translation and commercialization."
        />
        <meta property="og:image" content={"https://i.imgur.com/sSAVaHp.png"} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <Routes>
        <Route exact path="/" element={<OverallLayout />}>
          <Route
            exact
            path={NAV_ROUTES.get("HOME").PATH}
            element={<HomePage />}
          />

          <Route
            exact
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("DOCS").PATH
            }`}
            element={<DocsLayout />}
          >
            {Array.from(DOC_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}

            {Array.from(ENTERPRISE_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}

            {Array.from(UNIVERSAL_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}
          </Route>
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
