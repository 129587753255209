import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/104_ip_transfer.md";

const IpTransition = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpTransition;
