import { bodyWidth, theme } from "../../utils/theme";

export const styles = {
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 0,
    height: "100vh",
    position: "relative",
    width: "100%",
  },
  content: {
    height: "fit-content",
    pb: 6,
    pl: 6,
    pr: 6,
    width: { xs: "100%", md: "100%", lg: bodyWidth.maxWidth },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    alignItems: "center",
    justifyContent: "start",
    minHeight: "100vh",
    width: "100%",
  },
  scrollableContainer: {
    display: "flex",
    flex: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },
};
