import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/universal/201_ip_search.md";

const IpSearch = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default IpSearch;
