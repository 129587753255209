import { theme } from "../../utils/theme";

export const styles = {
  body: {
    display: "flex",
    mt: { xs: 5, md: 7 },
    position: "relative",
    width: "98%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  contentImageContainer: {
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: { xs: 240, sm: 380, md: 360 },
    position: { xs: "relative", md: "relative" },
    width: { xs: "100%", md: 519 },
    zIndex: -1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  listBullet: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
    minHeight: 4,
    ml: { xs: 2, md: 2 },
    mr: { xs: 4, md: 4 },
    mt: { xs: 1.4, md: 2.4 },
    minWidth: 4,
  },
  listContainer: {
    pb: 3,
    pl: { xs: 1, md: 1 },
    pr: { xs: 1, md: 0 },
    pt: { xs: 8, md: 8 },
    width: { xs: "100%", md: "100%" },
  },
  listHeader: {
    fontSize: {
      xs: theme.typography.fontSize * 1.35,
      md: theme.typography.fontSize * 1.35,
    },
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.1,
    mb: { xs: 5, md: 5 },
  },
  listItem: {
    alignItems: "flex-start",
    display: "flex",
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.15,
    },
    lineHeight: 1.1,
    mb: { xs: 3, md: 3 },
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectButton: {
    alignItems: "center",
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    color: theme.palette.grays[500],
    cursor: "pointer",
    display: "flex",
    padding: 3,
  },
  selectFormContainer: {
    display: { xs: "flex", md: "none" },
    flex: 1,
    flexDirection: "column",
    mb: 2,
    mt: 6,
    position: "relative",
    width: "100%",
  },
  selectFormValue: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize * 1,
  },
  selectMenu: {
    alignItems: "flex-start",
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    mt: 1,
    pb: 2,
    pl: 3,
    pr: 3,
    pt: 2,
    zIndex: 1,
  },
  selectOption: {
    pb: 2,
    pt: 2,
    width: "100%",
  },
  selectOptionLabel: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
  },
  tab: {
    color: theme.palette.grays[500],
    fontSize: theme.typography.fontSize * 1.13,
    fontWeight: 300,
    mr: 1.5,
    pl: { xs: 0, md: 5 },
    pr: { xs: 0, md: 5 },
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.grays.black,
      fontWeight: 700,
    },
  },
  tabs: {
    borderBottomColor: theme.palette.grays[300],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    mt: { xs: 8, md: 8 },
    width: { xs: "100%", md: "100%" },
  },
  valueRoot: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
};
