import React from "react";

import MarkdownContainer from "../../MarkdownContainer";

import markdown from "../../../../markdown/enterprise/301_agreement_creation.md";

const AgreementCreation = () => {
  return <MarkdownContainer markdown={markdown} />;
};

export default AgreementCreation;
