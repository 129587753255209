import { theme } from "../../utils/theme";

export const styles = {
  loaderContainer: {
    alignItems: "center",
    backgroundColor: theme.palette.grays[100],
    cursor: "default",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 9999,
  },
  text: {
    fontSize: "1rem",
    fontWeight: 500,
    letterSpacing: 1.2,
    lineHeight: 1,
    mb: 2,
    mt: 0,
  },
};
