import { bodyWidth, theme } from "../../utils/theme";

export const styles = {
  addressText: {
    color: theme.palette.grays.white,
    fontSize: theme.typography.fontSize * 1.4,
  },
  bulletSeperator: {
    backgroundColor: theme.palette.grays.white,
    borderRadius: 50,
    minHeight: 3,
    ml: { xs: 2, md: 2 },
    mr: { xs: 2, md: 2 },
    mt: { xs: 2, md: 2.4 },
    minWidth: 3,
  },
  copyrightText: {
    color: theme.palette.grays.white,
    fontSize: theme.typography.fontSize * 0.75,
    textAlign: { xs: "left", md: "right" },
  },
  sectionBody: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    pl: { xs: 4, sm: 8 },
    pr: { xs: 4, sm: 8 },
    width: "100%",
    zIndex: 1,
  },
  sectionContainer: {
    alignItems: "start",
    display: "flex",
    justifyContent: "center",
    pb: { xs: "35px", md: "35px" },
    pt: { xs: "35px", md: "35px" },
    position: "relative",
    width: { xs: "100%", md: "100%", lg: bodyWidth.maxWidth },
  },
  textContainer: {
    alignItems: "flex-start",
    display: "flex",
    mb: 6,
    mt: 6,
    width: "100%",
  },
  textLabel: {
    color: theme.palette.grays.white,
    fontSize: theme.typography.fontSize * 0.75,
    fontWeight: 700,
    textTransform: "capitalize",
  },
  textValue: {
    color: theme.palette.grays.white,
    fontSize: theme.typography.fontSize * 0.75,
    maxWidth: 300,
  },
  textLink: {
    color: theme.palette.grays.white,
    cursor: "pointer",
    fontSize: theme.typography.fontSize * 0.75,
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
  },
  footerDivider: {
    borderColor: theme.palette.grays[900],
  },
  footerHead: {
    alignItems: { xs: "flex-start", sm: "center" },
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: { xs: "center", sm: "flex-start" },
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  footerRoot: {
    alignItems: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  footerRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    mb: { xs: 2, md: 2 },
    mt: { xs: 2, md: 2 },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  socialMediaContainer: {
    display: "flex",
    mb: { xs: 2, sm: 0 },
    // marginTop: { xs: 6, sm: 0 },
    justifyContent: "space-between",
    width: { xs: 80, md: 100 },
  },
  socialMediaIcon: {
    color: theme.palette.grays.white,
    mr: 0,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      color: theme.palette.grays[400],
    },
  },
};
