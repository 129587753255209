import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { detectCurrentPath } from "../../../utils/formUtil";
import { theme } from "../../../utils/theme";

import { styles } from "./styles";

const SideMenu = ({ options }) => {
  const { state, dispatch } = useContext(GlobalContext);

  const location = useLocation();
  const { t } = useTranslation();

  const [toggleAccordions, setToggleAccordions] = useState([2, 3]);

  const currentPath = location.pathname;

  const handleToggleAccordion = (index) => () => {
    let clone = [];
    if (toggleAccordions.includes(index)) {
      clone = toggleAccordions.filter((f) => f !== index);
    } else {
      clone = [...toggleAccordions, index];
    }

    setToggleAccordions(clone);
  };

  return (
    <Box sx={styles.sideMenu}>
      <Box sx={styles.optionGroupContainer}>
        {Array.from(options)?.map(([key, value], index) =>
          value.CHILDREN.length > 0 ? (
            <Stack key={key}>
              <Button
                onClick={handleToggleAccordion(index)}
                sx={styles.optionContainerButton}
              >
                <Typography sx={styles.optionLabelInactive}>
                  {t(value.LABEL)}
                </Typography>

                {toggleAccordions.includes(index) ? (
                  <RiArrowDropDownLine
                    size="1.8em"
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: 2,
                    }}
                  />
                ) : (
                  <RiArrowDropRightLine
                    size="1.8em"
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: 2,
                    }}
                  />
                )}
              </Button>

              <Collapse
                in={toggleAccordions.includes(index)}
                collapsedSize={0}
                sx={styles.accordionContent}
              >
                {value.CHILDREN.map(([key, val], idx) => {
                  return (
                    <Link key={key} style={styles.optionLink} to={val.PATH}>
                      <Box
                        sx={
                          detectCurrentPath(currentPath, val.PATH)
                            ? styles.optionContainerActive
                            : styles.optionContainerInactive
                        }
                      >
                        <Typography sx={styles.accordionLabel}>
                          {t(val.LABEL)}
                        </Typography>
                      </Box>
                    </Link>
                  );
                })}
              </Collapse>
            </Stack>
          ) : (
            <Link key={key} style={styles.optionLink} to={value.PATH}>
              <Box
                sx={
                  detectCurrentPath(currentPath, value.PATH)
                    ? styles.optionContainerActive
                    : styles.optionContainerInactive
                }
              >
                <Typography sx={styles.optionLabelInactive}>
                  {t(value.LABEL)}
                </Typography>
              </Box>
            </Link>
          )
        )}
      </Box>
    </Box>
  );
};

export default SideMenu;
