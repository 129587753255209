import { appBar, bodyWidth, theme } from "../../../utils/theme";

export const styles = {
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  dialogBackground: {
    filter: "brightness(0.9) blur(2px)",
    height: "100vh",
    right: 0,
    top: 0,
    overflow: "hidden",
    position: "absolute",
    width: "100vw",
    zIndex: 0,
  },
  dialogHeader: {
    alignItems: "center",
    color: theme.palette.grays[700],
    display: "flex",
    height: appBar.height,
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    width: bodyWidth.width,
  },
  dialogHeaderContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },
  dialogListContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    pb: 4,
    pt: 8,
  },
  dialogVideo: {
    height: "100%",
    left: 0,
    position: "absolute",
    width: { xs: "inherit", md: "100%" },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  headerIcon:{
    color: theme.palette.grays.black,
  },
  icon: {
    alignItems: "center",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    ml: 3,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  navMenuContainer: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: { md: "none", xs: "flex" },
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  navText: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 500,
    letterSpacing: 1.2,
    mt: 2,
    mb: 2,
    ml: 9,
    textTransform: "inherit",
  },
};
