import PropTypes from "prop-types";
import React, { useReducer, createContext } from "react";
import globalReducer from "../reducers/globalReducer";

export const GlobalContext = createContext();

export const initialState = {
  currentView: undefined,
  loading: { message: "", isOpen: false },
  snackbar: { message: "", isOpen: false },
};

export const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
