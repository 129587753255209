import {
  Box,
  Button,
  Collapse,
  Dialog,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiArrowDropDownLine,
  RiArrowDropRightLine,
  RiCloseLine,
  RiListCheck,
} from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../Logo";

import { detectCurrentPath } from "../../../utils/formUtil";
import { theme } from "../../../utils/theme";

import { styles } from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SideMenuMobile = ({ options }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [toggleAccordions, setToggleAccordions] = useState([2, 3]);

  const currentPath = location.pathname;

  const handleOnClickOption = () => {
    setIsMenuOpen(false);
  };

  const handleToggleAccordion = (index) => () => {
    let clone = [];
    if (toggleAccordions.includes(index)) {
      clone = toggleAccordions.filter((f) => f !== index);
    } else {
      clone = [...toggleAccordions, index];
    }

    setToggleAccordions(clone);
  };

  return (
    <Box sx={styles.navMenuContainer}>
      <Box sx={styles.headerIcon}>
        <RiListCheck onClick={() => setIsMenuOpen(true)} size="1.4em" />
      </Box>

      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          style: { ...styles.dialog },
        }}
        TransitionComponent={Transition}
      >
        <Box sx={styles.dialogHeaderContainer}>
          <Box sx={styles.dialogHeader}>
            <Logo />

            <Box sx={styles.grow} />

            <RiCloseLine onClick={() => setIsMenuOpen(false)} size="1.2em" />
          </Box>
        </Box>

        <Box sx={styles.dialogListContainer}>
          {Array.from(options).map(([key, value], index) =>
            value.CHILDREN.length > 0 ? (
              <Stack key={key}>
                <Button
                  onClick={handleToggleAccordion(index)}
                  sx={styles.optionContainerButton}
                >
                  <Typography sx={styles.optionLabelInactive}>
                    {t(value.LABEL)}
                  </Typography>

                  {toggleAccordions.includes(index) ? (
                    <RiArrowDropDownLine
                      size="1.8em"
                      style={{
                        color: theme.palette.primary.main,
                        marginLeft: 2,
                      }}
                    />
                  ) : (
                    <RiArrowDropRightLine
                      size="1.8em"
                      style={{
                        color: theme.palette.primary.main,
                        marginLeft: 2,
                      }}
                    />
                  )}
                </Button>

                <Collapse
                  in={toggleAccordions.includes(index)}
                  collapsedSize={0}
                  sx={styles.accordionContent}
                >
                  {value.CHILDREN.map(([key, val], idx) => {
                    return (
                      <Link
                        key={key}
                        onClick={handleOnClickOption}
                        style={styles.optionLink}
                        to={val.PATH}
                      >
                        <Box
                          sx={
                            detectCurrentPath(currentPath, val.PATH)
                              ? styles.optionContainerActive
                              : styles.optionContainerInactive
                          }
                        >
                          <Typography sx={styles.accordionLabel}>
                            {t(val.LABEL)}
                          </Typography>
                        </Box>
                      </Link>
                    );
                  })}
                </Collapse>
              </Stack>
            ) : (
              <Link
                key={key}
                onClick={handleOnClickOption}
                style={styles.optionLink}
                to={value.PATH}
              >
                <Box
                  sx={
                    detectCurrentPath(currentPath, value.PATH)
                      ? styles.optionContainerActive
                      : styles.optionContainerInactive
                  }
                >
                  <Typography sx={styles.optionLabelInactive}>
                    {t(value.LABEL)}
                  </Typography>
                </Box>
              </Link>
            )
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default SideMenuMobile;
